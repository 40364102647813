
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation  } from "swiper";
// Import Swiper React components
function HomeStories() {
  

  return (
    <div className="App">
 

{/* <!-- Blog Section End --> */}
<div class="section" >
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Client Success Stories</h6>
                    <h2 class="main-title">Transforming clients' challenges into success stories.</h2>
                </div>
                {/* <!-- Section Title End --> */}

        
                <Swiper   
                    // style={{width: 700}}
                    spaceBetween={10}
                    slidesPerView={4}
        // centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        <SwiperSlide
        style={{borderRadius : 50, paddingBottom : 50}}
        >
        <img src="/assets/images/stories/01.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

    
        <SwiperSlide >
        <img src="/assets/images/stories/02.jpeg" alt="" className="col-lg-4 zoom-stories  img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide >
        <img src="/assets/images/stories/03.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide >
        <img src="/assets/images/stories/04.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/05.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/06.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/07.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/08.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/09.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/10.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/11.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/12.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/images/stories/13.jpeg" alt="" className="col-lg-4 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>
       
      </Swiper>

                        </div>
                        </div>
                
                    

    </div>
  );
}

export default HomeStories;
