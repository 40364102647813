import React, { useRef, useState } from "react";
// Import Swiper React components
function Contactus() {
  

  return (
    <div className="App">

{/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section contact-back-img" >
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">Contact Us</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Contact</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}

        {/* <!-- Contact Map Start --> */}
        <div class="section section-padding-02" >
            <div class="container">


                {/* <!-- Contact Map Wrapper Start --> */}
                <div class="contact-map-wrapper">
                    <iframe id="gmap_canvas" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Dietitian Neha Yaduvanshi | Nutritionist | Weight loss center | diabetes nutritionist | Jaipur, A/256, kirni phatak Rd, near Patanjali Store, Tara Nagar-A, Tara Nagar, Jhotwara, Jaipur, Rajasthan 302012&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
                {/* <!-- Contact Map Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Contact Map End --> */}

        {/* <!-- Contact Section Start --> */}
        <div class="section section-padding" style={{paddingBottom : 100}}>
            <div class="container">

                {/* <!-- Contact Wrapper Start --> */}
                <div class="contact-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            {/* <!-- Contact Content Start --> */}
                            <div class="contact-content">

                                {/* <!-- Contact Title Start --> */}
                                <div class="contact-title">
                                    <h4 class="sub-title">Don’t worry!</h4>
                                    <h2 class="main-title">If you have any query? Contact with us.</h2>
                                </div>
                                {/* <!-- Contact Title End --> */}

                                {/* <!-- Contact Form Start --> */}
                                <div class="contact-form">
                                    <form id="contact-form" action="https://htmldemo.net/nutras/nutras/assets/php/contact.php" method="POST">
                                        <div class="row gx-3">
                                            <div class="col-md-6">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input name="name" type="text" placeholder="Name" />
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-md-6">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input name="email" type="email" placeholder="Email" />
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-md-6">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input name="phone" type="text" placeholder="Phone" />
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-md-6">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input name="address" type="text" placeholder="Address" />
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-md-12">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <textarea name="message" placeholder="Message"></textarea>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <p class="form-message"></p>
                                            <div class="col-md-12">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <button type="submit" class="btn btn-secondary btn-hover-primary">Submit Now</button>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/* <!-- Contact Form End --> */}

                            </div>
                            {/* <!-- Contact Content End --> */}
                        </div>
                        <div class="col-lg-6">
                            {/* <!-- Contact Information Start --> */}
                            <div class="contact-information">
                                <div class="information-wrapper">

                                    <img class="shape" src="/assets/images/shape/shape-11.png" alt="" />

                                    <div class="single-info">
                                        <h3 class="info-title">Phone:</h3>

                                        <p>(+91) 8447009334</p>
                                        <p>(+91) 8949979942</p>

                                    </div>
                                    <div class="single-info">
                                        <h3 class="info-title">Email:</h3>

                                        <p>Sneha@healthfitsquare.com</p>
                                        {/* <p>Sneha@healthfitsquare.com</p> */}

                                    </div>

                                    <div class="single-info">
                                        <h3 class="info-title">Timings:</h3>

                                        <p>Weekdays : 10:00 AM - 7:00 PM</p>
                                        <p>Sunday : Closed</p>

                                    </div>

                                    {/* <div class="single-info">
                                        <h3 class="info-title">Address:</h3>

                                        <p>A/256, Khirni Phatak Road,<br/>
                                        Near Patanjali Store, Tara Nagar-A, <br/>
                                        Tara Nagar Jhotwara, <br/>
                                        Jaipur, Rajasthan 302012
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                            {/* <!-- Contact Information End --> */}
                        </div>

                           {/* <!-- Question & Answer End -->  */}
                           <div class="question-answer" style={{border : 0}}>

<div class="row">
    <div class="col-xl-4">
        {/* <!-- Answer User List Start --> */}
        <div class="answer-user-list">

            {/* <!-- Single User Start --> */}
            <div class="single-user active">
                
                <div class="user-content"  style={{maxWidth : '100%', paddingBottom : 0}}>
                    <h6 class="name">Jaipur Main Office</h6>
                    <p style={{marginTop : 10, marginBottom : 5}}>A/256, Khirni Phatak Road,<br/>
            Near Patanjali Store, Tara Nagar-A, <br/>
            Tara Nagar Jhotwara, <br/>
            Jaipur, Rajasthan 302012</p>
            <h7 class="name">Phone : </h7><span style={{fontSize : 12, color : '#fff'}}>(+91) 8447009334</span>
                </div>
            </div>
            {/* <!-- Single User End --> */}
            </div>
            </div>

            <div class="col-xl-4">
        {/* <!-- Answer User List Start --> */}
        <div class="answer-user-list">

            {/* <!-- Single User Start --> */}
            <div class="single-user active">
                
                <div class="user-content" style={{maxWidth : '100%', paddingBottom : 0}}>
                    <h6 class="name">Jaipur Branch Office</h6>
                    <p style={{marginTop : 10, marginBottom : 5}}>T9 Security Services Private Limited,<br/>
                    Office No.06 Pilot No 8, <br/>
                    Arjunpuri, Imli Phatak, <br/>
            Jaipur, Rajasthan 302015</p>
            <h7 class="name">Phone : </h7><span style={{fontSize : 12, color : '#fff'}}>(+91) 8949979942</span>

                </div>
            </div>
            {/* <!-- Single User End --> */}
            </div>
            </div>


            <div class="col-xl-4">
        {/* <!-- Answer User List Start --> */}
        <div class="answer-user-list">

            {/* <!-- Single User Start --> */}
            <div class="single-user active">
                
                <div class="user-content"  style={{maxWidth : '100%', paddingBottom : 0}}>
                    <h6 class="name">Gurgaon Office</h6>
                    <p style={{marginTop : 10, marginBottom : 5}}>Floor, V- 29/22, First,<br/>
                    near DLF Club, <br/>
            DLF Phase 3, Nathupur,  <br/>
            Gurugram, Haryana 122002</p>
            <h7 class="name">Phone : </h7><span style={{fontSize : 12, color : '#fff'}}>(+91) 8949979942</span>

                </div>
            </div>
            {/* <!-- Single User End --> */}
            </div>
            </div>
            </div>
            </div>

                    </div>
                </div>
                {/* <!-- Contact Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Contact Section End --> */}


    </div>
  )
}

export default Contactus
