
import React, { useEffect, useRef, useState } from "react";
import ServiceData from './json/service.json'
import { useParams } from "react-router-dom";
// Import Swiper React components
function ServicesDetail() {
    const params = useParams();

const toTitleCase = (str) => {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
      }


    //   useEffect(()=>{
    //     console.log(ServiceData.filter(val=> val.id == params.id)[0].image)
    //   })

  return (
    <div className="App">

        {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section contact-back-img" style={{padding : 0}}>
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content" style={{paddingBottom : 30}}>
                    <h2 class="page-title">{ServiceData.filter(val=> val.id == params.id)[0].name}</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Services</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}
 
 {/* <!-- Blog Section End --> */}
        <div class="section">
            <div class="container">


              
            <div class="section section-padding-02" style={{marginBottom : 100}}>
            <div class="container">

                {/* <!-- Blog Wrapper Start --> */}
                <div class="blog-wrapper-02">
                    <div class="row gx-xxl-5 flex-row-reverse">
                        <div class="col-lg-8">

                            {/* <!-- Blog Details Wrapper Start --> */}
                            <div class="blog-details-wrapper">

                                <div class="details-images" style={{paddingBottom : 0}}>
                                    <img src={ServiceData.filter(val=> val.id == params.id)[0].image} alt="Services Details"  style={{height : '20rem', width : '100%'}}/>
                                </div>

                              

                             
                                <div class="blog-details-description">
                                    <p>{ServiceData.filter(val=> val.id == params.id)[0].description}</p>

                                 
                                
                                </div>

                              

                            </div>
                         
                            {/* <!-- Blog Details Comment End --> */}

                        </div>
                        <div class="col-lg-4">

                            {/* <!-- Blog Sidebar Start --> */}
                            <div class="sidebar">


                                {/* <!-- Sidebar Widget Category Start --> */}
                                <div class="sidebar-widget">
                                    <h4 class="widget-title">Other Services</h4>

                                    <div class="widget-category">
                                        <ul class="category-list">
                                            {ServiceData.map((srv, index)=>(
                                                srv.id != params.id ? 
                                                <li><a href={`/services/${srv.id}/${srv.name.toLowerCase().replace(/ /g,'_').replace(/\//g, "$")}`}>{srv.name}</a></li> : ''
                                            ))}
                                            
                                            {/* <li><a href="#">Creative Writing <span>(03)</span></a></li>
                                            <li><a href="#">Graphic Design <span>(08)</span></a></li>
                                            <li><a href="#">Fine Arts <span>(18)</span></a></li>
                                            <li><a href="#">Business Analytics <span>(02)</span></a></li>
                                            <li><a href="#">Marketing <span>(14)</span></a></li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* <!-- Sidebar Widget Category End --> */}


                              

                            </div>
                            {/* <!-- Blog Sidebar End --> */}

                        </div>
                    </div>
                </div>
                {/* <!-- Blog Wrapper End --> */}

            </div>
        </div>

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

    </div>
  );
}

export default ServicesDetail;
