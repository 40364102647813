
import React, { useRef, useState } from "react";
// Import Swiper React components
function HomeProcedures() {
  

  return (
    <div className="App">
      {/* <!-- Features Section Start --> */}
        <div class="section section-padding">
            <div class="container">
                <div class="features-wrapper">
                     {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Our Procedures</h6>
                    <h2 class="main-title">Special Procedures for Clients</h2>
                </div>
                {/* <!-- Section Title End --> */}

                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                    <span><i class="flaticon-computer"></i></span>
                                </div>
                                <div class="feature-content">
                                    <div class="content-wrapper">
                                        <h4 class="title"><a href="#">Book Your Appointment</a></h4>
                                        <p>One step towards your health, book your appointment today.</p>
                                    </div>
                                    {/* <a href="#" class="arrow"><i class="icofont-long-arrow-right"></i></a> */}
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>
                     
                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                    <span><i class="flaticon-lunges"></i></span>
                                </div>
                                <div class="feature-content">
                                    <div class="content-wrapper">
                                        <h4 class="title"><a href="#">Full Body Analysis</a></h4>
                                        <p>We provide a full body analysis based on your diet history.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>


                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                    <span><i class="flaticon-lotus"></i></span>
                                </div>
                                <div class="feature-content">
                                    <div class="content-wrapper">
                                        <h4 class="title"><a href="#">Customized Meal Plans
</a></h4>
                                        <p>In this step we create perfect suited diet plan for you.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>


                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                    <span><i class="flaticon-lunges"></i></span>
                                </div>
                                <div class="feature-content">
                                    <div class="content-wrapper">
                                        <h4 class="title"><a href="#">Track Health Progress
</a></h4>
                                        <p>We care for you that is why we keep track of your health progress.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Features Section End --> */}


        {/* <!-- Testimonial Section Start --> */}
        <div class="section section-padding testimonial-section testimonial-active" style={{paddingTop : 80, paddingBottom : 80}}>

            <img class="shape-01 animation-down" src="/assets/images/shape/shape-5.png" alt="Shape" />
            <img class="shape-02 animation-up" src="/assets/images/shape/shape-6.png" alt="Shape" />

            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title section-title-white">
                    <h6 class="sub-title"> Stay Healthy, Stay Happy</h6>
                    <h2 class="main-title">Treat Yourself for Rejuvenating Your Body & Soul</h2>
                </div>
                {/* <!-- Section Title End --> */}

                {/* <!-- Testimonial Wrapper End --> */}
                <div class="testimonial-wrapper" style={{marginTop : 20}}>
                    <div >
                     
                            {/* <!-- Single Testimonial Start --> */}
                            <div class="single-testimonial swiper-slide">
                                
                                <p style={{marginTop : 0}}>Nutrition is the science or practice of consuming food in relation to a body's dietary needs, while diet essentially means habitually taken food and drink. The word diet is also used to describe the act of restricting oneself to small amount of food or special kind of food either for some medical reason or to lose weight.</p>
                               
                            </div>

                           <div style={{textAlign : 'center', marginTop : 30}}> <a href="/contact-us" class="btn btn-primary btn-hover-secondary">Make an Appointment</a></div>
                          
                    </div>
                </div>
                {/* <!-- Testimonial Wrapper End --> */}

            </div>


        </div>
        {/* <!-- Testimonial Section End --> */}

    </div>
  );
}

export default HomeProcedures;
