
import moment from "moment";
import React, { useRef, useState } from "react";
// Import Swiper React components
function Footer() {
  

  return (
    <div className="App">
 
 {/* <!-- Footer Start --> */}
        <div class="section footer-section">

            {/* <!-- Footer Widget Section Start --> */}
            <div class="footer-widget-section section-padding" style={{paddingBottom : '80px'}}>

                <img class="shape-01 animation-rotate" src="/assets/images/shape/shape-9.png" alt="Shape" />

                <div class="container">

                    {/* <!-- Footer Widget Wrapper Start --> */}
                    <div class="footer-widget-wrapper">
                        <div class="row">
                            <div class="col-lg-4">

                                {/* <!-- Footer Widget Start --> */}
                                <div class="footer-widget">
                                    <h3 class="footer-widget-title"><img src="/assets/images/logo.png" alt="Logo" /></h3>

                                    <div class="widget-newsletter">
                                        <p>If you are looking for a complete health plan for you and your family, then you are right place. Here at Health fit Square We consider our personalized nutrition plan a game changer for you. We guide you to good health by creating a customized nutrition plan which includes amazing health tips and recipes.</p>


                                    </div>
                                </div>
                                {/* <!-- Footer Widget End --> */}

                            </div>
                            <div class="col-lg-8">

                                {/* <!-- Footer Widget Start --> */}
                                <div class="widget-wrapper">

                                    {/* <!-- Footer Widget Start --> */}
                                    <div class="footer-widget">
                                        <h3 class="footer-widget-title">Quick Links</h3>

                                        <ul class="widget-link">
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/about-us">About us</a></li>
                                            <li><a href="/packages">Packages</a></li>
                                            <li><a href="/services">Services</a></li>
                                            {/* <li><a href="/yoga-and-workout">Yoga & Workout</a></li> */}
                                            <li><a href="/stories">Stories</a></li>
                                            <li><a href="/contact-us">Contact</a></li>

                                        </ul>
                                    </div>
                                    {/* <!-- Footer Widget End --> */}

                                    {/* <!-- Footer Widget Start --> */}
                                    <div class="footer-widget">
                                        <h3 class="footer-widget-title">Help Links</h3>

                                        <ul class="widget-link">
                                            <li><a href="#">Privacy Policy</a></li>
                                            <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                                            <li><a href="#">FAQ’s</a></li>
                                        </ul>
                                    </div>
                                    {/* <!-- Footer Widget End --> */}

                                    {/* <!-- Footer Widget Start --> */}
                                    <div class="footer-widget">
                                        <h3 class="footer-widget-title">Opening Hours</h3>

                                        <ul class="widget-link">
                                            <li><a href="#">Mon-Sat: 10 AM – 7 PM</a></li>
                                            <li><a href="#">Sunday: Closed</a></li>
                                        </ul>

                                        <div class="widget-location">
                                            <h3 class="footer-widget-title">Location</h3>

                                            <p>A/256, Khirni Phatak Road,<br/>
                                        Near Patanjali Store, Tara Nagar-A, <br/>
                                        Tara Nagar Jhotwara, <br/>
                                        Jaipur, Rajasthan 302012</p>
                                        </div>
                                    </div>
                                    {/* <!-- Footer Widget End --> */}

                                </div>
                                {/* <!-- Footer Widget End --> */}

                            </div>
                        </div>
                    </div>
                    {/* <!-- Footer Widget Wrapper End --> */}

                </div>

                <img class="shape-02 animation-rotate" src="/assets/images/shape/shape-9.png" alt="Shape" />

            </div>
            {/* <!-- Footer Widget Section End --> */}

            {/* <!-- Footer Copyright Section Start --> */}
            <div class="footer-copyright">
                <div class="container">

                    {/* <!-- Copyright Wrapper Start --> */}
                    <div class="copyright-wrapper">

                        <div class="copyright-text">
                            <p>&copy; {moment().format('YYYY')} <span> HealthFit Square. </span> All Rights Reserved.</p>
                        </div>

                        {/* <div class="copyright-link">
                            <a href="#">Terms of Service</a>
                            <a href="#">Privacy Policy</a>
                            <a href="#">Sitemap</a>
                            <a href="#">Security</a>
                        </div> */}

                    </div>
                    {/* <!-- Copyright Wrapper End --> */}

                </div>
            </div>
            {/* <!-- Footer Copyright Section End --> */}

        </div>
        {/* <!-- Footer End --> */}

        {/* <!--Back To Start--> */}
        <a href="#" class="back-to-top">
            <i class="icofont-simple-up"></i>
        </a>
        {/* <!--Back To End--> */}
    </div>
  );
}

export default Footer;
