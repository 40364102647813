
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
function Aboutus() {
  
    const [counter1, setCounter1] = useState(0)
    const [counter2, setCounter2] = useState(0)
    const [counter3, setCounter3] = useState(0)
    const [counter4, setCounter4] = useState(0)

    

    useEffect(() => {
        for(let i = counter1; i< 847; i++){
            setCounter1(counter1 + 1)
        }

        for(let i = counter2; i< 592; i++){
            setCounter2(counter2 + 1)
        }

        for(let i = counter3; i< 263; i++){
            setCounter3(counter3 + 1)
        }

        for(let i = counter4; i< 1570; i++){
            setCounter4(counter4 + 1)
        }
    }, [counter1, counter2,counter3, counter4])

  return (
    <div className="App">
 

 {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section about-back-img">
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">About Us</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">About Us</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}

     
      {/* <!-- About Section Start --> */}
      <div class="section section-padding overflow-hidden" style={{paddingTop : '80px', paddingBottom : '80px'}}>
            <div class="container">

                {/* <!-- About Wrapper Start --> */}
                <div class="about-wrapper">
                    <div class="row align-items-center gx-xxl-5">
                        <div class="col-lg-5">
                            {/* <!-- About Images Start --> */}
                            <div class="about-images">
                                {/* <img src="assets/images/shape/shape-2.png" alt="shape" class="shape" /> */}
                                <div class="images">
                                    <img src="assets/images/neha.jpg" alt="About" />
                                </div>
                                <div class="about-btn">
                                    <a href="/contact-us" class="btn btn-primary btn-hover-secondary">Meet the Dietician</a>
                                </div>
                            </div>
                            {/* <!-- About Images End --> */}
                        </div>
                        <div class="col-lg-7">
                            {/* <!-- About Content Start --> */}
                            <div class="about-content bg-white">
                                <img class="shape" src="/assets/images/shape/shape-1.png" alt="shape" />
                                <div class="content-wrapper">
                                    <h6 class="sub-title">6+ years of experience</h6>
                                    <h2 class="main-title">Welcome to HealthFit Square <br/></h2>
<h3>Dt Neha Yaduvanshi</h3>
                                    <p>Dietitian Neha Yaduvanshi founded Healthfitsquare in 2019, a diet clinic situated in Jaipur, with a mission to make everyone healthy & happy by healthy eating and lifestyle habits.<br/>
                                    With a small but well connected team, she is serving personalized diet & lifestyle plans to clients and patients worldwide to manage weight, obesity, Thyroid, PCOD/S, Diabetes, and all other lifestyle disorders. </p>
                                    <span class="count">Best Health Advices</span>
                                    <p>We do not believe in fancy diet, strict nutrition philosophies, staying unrealistically thin, or depriving yourself of the foods you love. Rather, she encourages use of food as medicine with a holistic approach.<br/>
                                    With unique yet simple presentation of videos on social media always have a personal touch, which allows us to deeply connect and engage with people’s lives and deliver the message effectively. dietitian is a trained professional whose job is to guide patients and people to make changes or modifications in their diet to improve their health and lifestyle. 
</p>
                                </div>
                            </div>
                            {/* <!-- About Content End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- About Wrapper End --> */}

            </div>
        </div>
        {/* <!-- About Section End --> */}



     



        {/* <!-- Testimonial Section Start --> */}
        <div class="section section-padding testimonial-section testimonial-active">

            <img class="shape-01 animation-down" src="/assets/images/shape/shape-5.png" alt="Shape" />
            <img class="shape-02 animation-up" src="/assets/images/shape/shape-6.png" alt="Shape" />

            <div class="container">


                {/* <!-- Testimonial Wrapper End --> */}
                <div >
                    <div class="swiper-container">
                        <div class="swiper-wrapper">


                        <div class="row">
                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                <div class='sm-cls'>
                                    <span style={{fontSize : 40}}>{counter1}</span>
                                </div>
                                </div>
                                <div class="feature-content">
                                    <div>
                                        <h4 class="title" style={{color : '#fff', fontSize : 30}}>Sports <br/>Nutrition</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>
                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                <div class='sm-cls'>
                                <span style={{fontSize : 40}}>{counter2}</span>
                                </div>
                                </div>
                                <div class="feature-content">
                                    <div>
                                        <h4 class="title" style={{backgroundColor : "#0a4d3c", color : '#fff', fontSize : 30}}>Weight Loss Therapy</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>
                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                <div class='sm-cls'>
                                <span style={{fontSize : 40}}>{counter3}</span>

                                </div>
                                </div>
                                <div class="feature-content">
                                    <div >
                                        <h4 class="title" style={{backgroundColor : "#0a4d3c", color : '#fff', fontSize : 30}}>Therpeutic <br/>Diet</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>


                        <div class="col-lg-3 col-md-6">
                            {/* <!-- Single Feature Start --> */}
                            <div class="single-feature">
                                <div class="feature-icon">
                                    <div class='sm-cls'>
                                    <span style={{fontSize : 40}}>{counter4}</span>

                                </div>
                                </div>
                                <div class="feature-content">
                                    <div>
                                        <h4 class="title" style={{backgroundColor : "#0a4d3c", color : '#fff', fontSize : 30}}>Happy Customers</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Single Feature End --> */}
                        </div>
                    </div>
                    

                        </div>
                    </div>
                </div>
                {/* <!-- Testimonial Wrapper End --> */}

            </div>

          

        </div>
        {/* <!-- Testimonial Section End --> */}

      {/* <!-- Our Mission & Vission Section Start --> */}
      <div class="section section-padding bg-grey">
            <div class="container">

                {/* <!-- Our Mission Wrapper Start --> */}
                <div class="our-mission-wrapper">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="content">
                                <h4 class="sub-title">Our Mission & Vision</h4>
                                <h2 class="main-title">HealthfitSquare is a diet clinic.</h2>
                                <p>So here we are, on a mission to show the world that a healthy lifestyle is not dreadful, but in fact, is the best thing we can do to reward our body for all its hard work. </p>

                                <p>A future in which all people enjoy the life-enhancing benefits of good nutrition, in health and disease.</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="content-list">
                                <ul>
                                    <li>We consider our personalized nutrition plan a game changer for you. </li>
                                    <li>We guide you to good health by creating a customized nutrition plan which includes amazing health tips and recipes.</li>
                                    <li>We create personalized diet plans keeping the calories you should be consuming each day; no more, no less.</li>
                                </ul>
                               <div className="row" style={{marginTop : 20}}><h3 className="col-xl-4" style={{margin: 'auto 0px auto 0px'}}>Need advice?</h3> 
                                <a class="btn btn-primary btn-hover-secondary col-xl-6" href="#" style={{margin : 0}}>call +91-8447009334</a></div> 
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Our Mission Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Our Mission & Vission Section End --> */}

        

    </div>
  );
}

export default Aboutus;
