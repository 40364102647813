import React, {useState} from "react";
import { BrowserRouter as Router, NavLink, Route,Routes } from "react-router-dom";
import Aboutus from "./Aboutus.js";
import Contactus from "./Contactus.js";
import Footer from "./Footer.js";
import Home from "./Home";
import Packages from "./Packages.js";
import Payment from "./Payment.js";
import Services from "./Services.js";
import ServicesDetail from "./ServicesDetail.js";
import Stories from "./Stories.js";
import TermsConditions from "./TermsConditions.js";
import Yoga from "./yoga.js";

function Header() {


  return (
    <div className="App">

   {/* <!-- Header Section Start --> */}
        <div class="header section">

       
            {/* <!-- Header Main Start --> */}
            <div class="header-main">
                
            <div style={{background : '#0A4D3C',height : 40, width : '100%',}}>

            <div className="row" style={{    height: '100%',
    alignItems: 'center'}}>
        <div className="col-lg-6">

        <ul class="social row" style={{justifyContent : 'start', marginLeft : 50}}>
            <li className="col-lg-7 col-sm-6 row small-width" style={{color : 'white',alignItems : 'center'}}>
            <i class="fa fa-phone col-lg-1 col-sm-1 small-width-i"></i>
                <a href="Tel:918447009334" target={'_blank'} className="col-lg-8 col-sm-8 small-width-a"
                style={{fontSize : 14, padding : 0}}
                >
                    +91 84470 09334
                    </a>
                    </li>
           
                    <li className="col-lg-5 col-sm-5 row small-width" style={{color : 'white',alignItems : 'center'}}>
            <i class="fa fa-envelope col-lg-1 col-sm-1 small-width-i2"></i>
                <a href="mailto:sneha@healthfitsquare.com" target={'_blank'} className="col-lg-8 col-sm-8 small-width-a"
                style={{fontSize : 14, paddingLeft : 10}}
                >
                   sneha@healthfitsquare.com
                    </a>
                    </li>
        </ul>
        </div>

            <div className="col-lg-6 mob-resp">

         
             <ul class="social row" style={{justifyContent : 'end', marginRight : 50}}>
            <li className="col-lg-1" style={{color : 'white'}}><a href="https://www.facebook.com/Dietitiannehayaduvanshi/" target={'_blank'}><i class="icofont-facebook"></i></a></li>
            <li className="col-lg-1" style={{color : 'white'}}><a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}><i class="icofont-instagram"></i></a></li>
            <li className="col-lg-1" style={{color : 'white'}}><a href="https://twitter.com/healthfitsquare" target={'_blank'}><i class="icofont-twitter"></i></a></li>
            <li className="col-lg-1" style={{color : 'white'}}><a href="https://wa.me/+918447009334" target={'_blank'}><i class="icofont-whatsapp"></i></a></li>
        </ul>
                                   
                           
                        {/* </div> */}
         

</div>
            {/* <div className="col-lg-6 row" style={{justifyContent :'end'}}>

           <div className="col-lg-1" style={{color : 'white', border : '1px solid #dcdcdc'}}><a href="https://www.facebook.com/Dietitiannehayaduvanshi/" target={'_blank'}><i class="icofont-facebook"></i></a></div> 
           <div className="col-lg-1" style={{color : 'white'}}> <a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}><i class="icofont-instagram"></i></a></div> 
           <div className="col-lg-1" style={{color : 'white'}}> <a href="https://twitter.com/healthfitsquare" target={'_blank'}><i class="icofont-twitter"></i></a></div> 
           <div className="col-lg-1" style={{color : 'white'}}><a href="https://wa.me/+918447009334" target={'_blank'}><i class="icofont-whatsapp"></i></a></div>

           </div>  */}

          
    </div>
    </div>
                <div class="container" style={{    marginTop: '20px'}}>


                    {/* <!-- Header Wrapper Start --> */}
                    <div class="header-wrapper">

                        {/* <!-- Header Logo Start --> */}
                        <div class="header-logo">
                            <a href="/"><img src="/assets/images/logo.png" alt="Logo" /></a>
                        </div>
                        {/* <!-- Header Logo End --> */}

                        {/* <!-- Header Menu Start --> */}
                        <div class="header-menu d-none d-lg-flex">

                            <ul class="nav-menu">
                                <li><NavLink exact activeClassName="active" to="/">Home</NavLink></li>
                                <li><NavLink activeClassName="active" to="/about-us">About Us</NavLink></li>
                                <li>
                                    <NavLink activeClassName="active" to="/packages">Packages</NavLink>
                                   
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/services">Services </NavLink>
                                
                                </li>

                                <li>
                                    <NavLink activeClassName="active" to="/payment-process">Payment </NavLink>
                                
                                </li>
                                {/* <li>
                                    <a href="/yoga-and-workout">Yoga & Workout</a>
                                 
                                </li> */}
                                <li><NavLink activeClassName="active" to="/stories">Stories</NavLink></li>

                                <li><NavLink activeClassName="active" to="/contact-us">Contact</NavLink></li>
                            </ul>


                            {/* <div class="header-btn">
                                <a class="btn btn-secondary btn-hover-primary" href="login.html">Sign Up</a>
                            </div> */}

                        </div>
                        {/* <!-- Header Menu End --> */}

                        {/* <!-- Header Toggle Start --> */}
                        <div class="header-toggle d-lg-none">

                            {/* <a class="btn btn-secondary btn-hover-primary" href="login.html">Sign Up</a> */}

                            <a href="#" class="menu-toggle">
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>
                        </div>
                        {/* <!-- Header Toggle End --> */}

                    </div>
                    {/* <!-- Header Wrapper End --> */}

                </div>
            </div>
            {/* <!-- Header Main End --> */}

        </div>
        {/* <!-- Header Section End --> */}

        {/* <!-- Mobile Menu Start --> */}
        <div class="mobile-menu">

            {/* <!-- Menu Close Start --> */}
            <a class="menu-close" href="javascript:void(0)">
                <i class="icofont-close-line"></i>
            </a>
            {/* <!-- Menu Close End --> */}

            {/* <!-- Mobile Menu Start --> */}
            <div class="mobile-menu-items">
                <ul class="nav-menu">
                    <li><a class="active" href="/">Home</a></li>
                    <li><a href="">About Us</a></li>
                    <li>
                        <a href="#">Packages</a>
                        <ul class="sub-menu">
                            <li><a href="courses.html">Courses</a></li>
                            <li><a href="my-courses.html">My Courses</a></li>
                            <li><a href="#">Courses Details</a>
                                <ul class="sub-menu">
                                    <li><a href="courses-details-left-sidebar.html">Courses Details Left Sidebar</a></li>
                                    <li><a href="courses-details-right-sidebar.html">Courses Details Right Sidebar</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">Services </a>
                        <ul class="sub-menu">
                            <li><a href="after-enroll.html">After Enroll</a></li>
                            <li><a href="courses-admin.html">Instructor Dashboard (Course List)</a></li>
                            <li><a href="overview.html">Instructor Dashboard (Performance)</a></li>
                            <li><a href="students.html">Students</a></li>
                            <li><a href="reviews.html">Reviews</a></li>
                            <li><a href="engagement.html">Course engagement</a></li>
                            <li><a href="traffic-conversion.html">Traffic & conversion</a></li>
                            <li><a href="messages.html">Messages</a></li>
                            <li><a href="register.html">Register</a></li>
                            <li><a href="login.html">Login</a></li>
                            <li><a href="faq.html">FAQ</a></li>
                            <li><a href="404-error.html">404 Error</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">Yoga & Workout</a>
                        <ul class="sub-menu">
                            <li>
                                <a href="#">Blog</a>
                                <ul class="sub-menu">
                                    <li><a href="blog-grid.html">Blog Grid</a></li>
                                    <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                                    <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">Blog Details</a>
                                <ul class="sub-menu">
                                    <li><a href="blog-details-left-sidebar.html">Blog Details Left Sidebar</a></li>
                                    <li><a href="blog-details-right-sidebar.html">Blog Details Right Sidebar</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><a href="contact.html">Stories</a></li>

                    <li><a href="contact.html">Contact</a></li>
                </ul>

            </div>
            {/* <!-- Mobile Menu End --> */}

        </div>
        {/* <!-- Mobile Menu End --> */}

        {/* <!-- Overlay Start --> */}
        <div class="overlay"></div>
        {/* <!-- Overlay End --> */}


        <Routes>
<Route exact path={"/"} element={<Home />} />
<Route exact path={"/about-us"} element={<Aboutus />} />
<Route exact path={"/contact-us"} element={<Contactus />} />
<Route exact path={"/services"} element={<Services />} />
<Route exact path={"/yoga-and-workout"} element={<Yoga />} />
<Route exact path={"/packages"} element={<Packages />} />
<Route exact path={"/stories"} element={<Stories />} />
<Route exact path={"/terms-and-conditions"} element={<TermsConditions />} />
<Route exact path={"/services/:id/:name"} element={<ServicesDetail />} />
<Route exact path={"/payment-process"} element={<Payment />} />




</Routes>
<Footer />
    </div>
  );
}

export default Header;
