
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation  } from "swiper";

// Import Swiper React components
function HomeReviews() {
  

  return (
    <div className="App">
 
  {/* <!-- Blog Section End --> */}
  <div class="section section-padding-02" style={{paddingBottom : 0}}>
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Our Client Say</h6>
                    <h2 class="main-title">What they say is always right</h2>
                </div>
                {/* <!-- Section Title End --> */}

           

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

                
                        {/* <!-- Testimonial Section Start --> */}
        <div class="section section-padding testimonial-section testimonial-active" style={{paddingTop : 80, paddingBottom : 80, backgroundColor : '#fff'}}>

<img class="shape-01 animation-down" src="/assets/images/shape/shape-5.png" alt="Shape" />
<img class="shape-02 animation-up" src="/assets/images/shape/shape-6.png" alt="Shape" />

<div class="container">


    {/* <!-- Testimonial Wrapper End --> */}
    <div class="testimonial-wrapper" style={{marginTop : 20}}>
        <div >
         
                {/* <!-- Single Testimonial Start --> */}
                <div class="single-testimonial swiper-slide">
                    
                    {/* <p style={{marginTop : 0}}>Nutrition is the science or practice of consuming food in relation to a body's dietary needs, while diet essentially means habitually taken food and drink. The word diet is also used to describe the act of restricting oneself to small amount of food or special kind of food either for some medical reason or to lose weight.</p> */}
                   
                    <Swiper   
                    // style={{width: 700}}
                    spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        <SwiperSlide  style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>I had an excellent experience with Dr Neha yaduvanshi.Her meal plans are well customised according to one's preference. Her 3 months diet plans and guidance gave me an excellent habit of eating healthy everyday and had a very positive impact on my daily routine of exercise,diet and my lifestyle. Thank you Mam !!!</p>
                                <h4 class="name">Priyanshu Agarwal </h4>
                                <span class="designation">Jaipur</span>
        </SwiperSlide>

        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>l am so happy to have approached DR Neha. I have gained weight during pregnancy,
lost 7 kgs in 2 months effortlessly and all the credit goes to her for understanding
my lifestyle and giving me customized diet every week without repeating dishes.
Though 'am based in G.Noida, she has never let distance or time be a barrier for our daily conversations. Also, Neha is very different from other nutritionists, as she is at per with latest diet foods& detox trends happening around the globe. She is very supportive & her motivation will only encourage you to feel & look better.Bottom line XXl to Large that too in 2 months.I would totally recommend her to every one. Everyone looking for a lifestyle change and longterm weight loss.</p>
<h4 class="name">Suman Saini</h4>
                                <span class="designation">Noida</span>
                              
        </SwiperSlide>


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>Dr. Neha is amazing. I had never heard such kind of diet. She actually is a live example of "don't eat less, eat right". Her diet plans have not only helped me to believe that I too can get back in the shape, rather she has taught a way of life to go ahead. In the last 3 months of journey, I have learnt to love food, to enjoy food, to cherish each bite, to respect food and to decide food (means what to eat).. She is different and I would love to say to everyone that she will make you different for sure. Thank you Ma'am❤️</p>
        <h4 class="name">Shikha Jain</h4>
                                <span class="designation">Bangalore</span>      
        </SwiperSlide>


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>Dietitian Neha is superb in her work. I tried so many things to loose my weight from so long but not got the satisfactory results, I spent so much on my weight but results was not satisfactory and I wasdisappointed. But Dt. Neha has changed my perception about my weight her motivation and diet plan changed my body shape completely and gave me a new confidence.
She allowed me everything in my diet whatever I liked even tea also. with my favorite food items and with some exercises I reduced 12 kg in just 3 months and the journey still going on
Thank you dear without you it could not be possible.</p>
                                <h4 class="name">Shweta Sharma</h4>
                                <span class="designation">London</span>
        </SwiperSlide>


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>I had no idea that just losing weight would make me look so good. I feel lucky that I chose to come to you and take your suggestions about dieting. I am so interested in you for giving me the best advice that helped me to lose weight so soon and in a healthy way. Thank you Neha ji.</p>
                                <h4 class="name">Ankit Agarwal</h4>
                                {/* <span class="designation">London</span> */}
        </SwiperSlide>


        {/* <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>Neha has made the impossible possible for me by coming into my life. I used to have no direction or motivation to lose weight. Would look in the mirror and used to promise myself every night that I will start dieting from the next day. But would fail the very next morning. Then I started exercising. Lots of vigorous walking, exercise equipment and what not but I still wouldn't lose any weight. In fact I started gaining as I was eating more after exercise. And then I came to know about Neha. She showed me the right way to eat, right quantities, right mindset, basically all things right. If I started wavering, I knew Neha would find out despite me living so far away. Thats because she would regularly follow up and keep me in line with the diet and exercise routine. I have lost 10 kilos in 4 months and  still need to lose another 10 to come to a healthy weight range. Working under her guidance has benefitted me immensely as I have gained energy, my skin has cleared, and many other ailments have disappeared. I cannot recommend her highly enough. 10 stars to you. Thank you Neha!</p>
                                <h4 class="name">Amrita Rawal </h4>
                                <span class="designation">Sydney</span>
        </SwiperSlide> */}


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>You are extremely knowledgeable,excellent at communicating and just plain fun to talk to.I have learned so many in just 3 months.1st one month I didn’t loose even 1 kg but after that i lost 15 kg in 4 months.This is the first time i feel satisfied,my craving have diminished dramatically and i have a whole new relationship with food.i am eating guilt free for the first time in my life .My energy has also dramatically increased and I m feeling great. I enjoyed my session with you a lot .Really appreciate the knowledge you have and the help you gave me to look at the things a more healthy way .Just loving this healthy feeling.<br/>Thank you so much 😊❤️</p>
                                <h4 class="name">Khushboo Jain</h4>
                                <span class="designation">Bangkok</span>
        </SwiperSlide>


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>I have been consulting with Dr. Neha for my over weight and slip disc issues and have lost 16kg weightin 4 months with her proper guidance. she is hands on and always available to answer my questions. The best part of the program is that she does not only tell me what to eat but educated me about the best combinations, how Calories work. I have not felt that I am hungry ever and my slipdiscs are gone that are usually associated with diets. Thank you Healthfitsquare dr. Neha yaduvanshi</p>
                                <h4 class="name">Govind Tambi</h4>
                                <span class="designation">Mumbai</span>
        </SwiperSlide>


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>A definite recommendation... ! She turned around my mindset and Myths regarding weight loss .. "EAT MORE TO LOOSE MORE" - Thank you neha ma'am to make me believe in this and the entire journey.!</p>
                                <h4 class="name">Samridhi Jain</h4>
                                <span class="designation">Karnataka</span>
        </SwiperSlide>


        <SwiperSlide style={{background : '#0a4d3c', borderRadius : 50, padding : '0px 30px 30px 30px'}}><p>Diet Ka sochte hi hum phle hi dar jate h lekin Dr.Neha me mere diet k dar ko bhaga Diya...Agar apko diet Apne choices &Apne time management k Saath mile to diet kise na achi lage....Mene dieting phle bhi ki, weight loss to hua lekin stamina , nutrition loss,BP low ,sugar low Kai problem aagyi.Doctor me dieting k liya mana kiya,...me upset ho gyi ki aage me weight loss nhi kr paungi...Meri friend Seema ne tumhara name suggest Kiya .Tumhari ek month ki diet se meraBp low & Sugar low Ka problem solve hua Saath hi Saath Mera stamina boost up hua..or progress abhi aage BHI hogi...😊😊
<br/>A big thanks to neha💐💐❤️❤️</p>
                                <h4 class="name">Neetu Chouhan </h4>
                                <span class="designation">Jaipur</span>
        </SwiperSlide>

       
      </Swiper>
                </div>

          
        </div>
    </div>
    {/* <!-- Testimonial Wrapper End --> */}

</div>


</div>
{/* <!-- Testimonial Section End --> */}
                        
    
        </div>
  );
}

export default HomeReviews;
