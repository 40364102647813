
import React, { useRef, useState } from "react";
// Import Swiper React components
function TermsConditions() {
  

  return (
    <div className="App">

    
 
 {/* <!-- Blog Section End --> */}
        <div class="section section-padding-02 section-padding">
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title"></h6>
                    <h2 class="main-title">Terms & Conditions</h2>
                </div>
                {/* <!-- Section Title End --> */}

                {/* <!-- Blog Wrapper Start --> */}
                <div class="blog-wrapper">
                    <ul>
                        <li>
                        <span>1.</span> You are consulting with qualified dietitians and clinic nutritionists. Though we would provide you with diet & nutrition advice after bodily analysis and a health assessment, it in no manner replaces or substitutes for the services of a physician or a doctor.
                        </li>
                            <br/>

                        <li>

                        <span>2.</span> We are providing you with an opportunity to turn around your life. This effort involves the investment of our time, and as a wise person once said, ‘Time is money’. We would be charging you for our expertise in the field, and expect you to respect it. We are not indebted to you in any way for the money you pay us, neither can it be used to take advantage of us in any way.
                        </li>
                        <br/>

                        <li>
                        <span>3.</span> The payment must be made in full at the time of enrollment either by cash or online. 
                        </li>

                        <br/>

                        <li>
                        <h3>Refunds </h3> Once you have paid for a program, the no refund and no transfer policy applies. due to the nature of services involved no refund and no transfer is provided under any circumstances.
                        </li>
<br/>
                        <li>
                        <h3>Transfers</h3> Our programs are non-transferable. If, for a medical or other urgent reason, you are not able to continue the program, we may be able to approve the transfer request provided you can present relevant evidence. Note that if approved, standard transfer fee of Rs.1000/- (in case of transfer to existing client) or Rs.1500/- (in case of transfer to a non-member) will be applicable.
                        </li>
                        <br/>
                        <li>
                        <span>6.</span> We do not encourage breaks as they hinder the flow of diet and lifestyle changes that we recommend. Moreover, lifestyle changes are about integrating those into your life regardless of what you are up to. Vacations, family functions, travelling or not feeling well should not become a factor to stop eating healthy. Though if for some other emergent reason, you need to pause your program, please get in touch with us. If the hold is approved, you are entitled to have a maximum of 10days break in 3month program, and 20 days break in 6 month program. Hold/Pause facility is not applicable to 1 month program.
                        </li>
                        <br/>
                        <li>
                        <span>7.</span> We aim to improve your lifestyle, And it's a natural process, which takes a little time and also depends on your efforts so you should not guess that diseases like thyroid, blood pressure, PCOS, etc. get cured immediately because it seems unrealistic. Changes in lifestyle are related to changes in habits, so do not expect such a miracle at all in a week or a month's time. The disease may or may not be completely cured, it can be done with appropriate changes in your diet and lifestyle, as we believe in correcting the root causes rather than the symptoms.
                        </li>
                        <br/>
                        <li><span>8.</span> We do not guarantee that you will lose so much weight in such a certain number of days. It is a time consuming and healthy process. And it will not happen that after this process is over, your weight will increase again. You just have to correct your habits, after that you can expect life-long results.</li>
                       <br/>
                        <li>
                 <span>9.</span> We believe on improving your health and heal your inner self through natural course, but if need be, we might recommend some dietary supplements. It is completely your choice whether or not to take those supplements, or whether you’d like to consult your physician before doing so. You are in no way bound to take them.
                 </li>
                        <br/>
                        <li><h4>Misconduct and Misbehavior </h4> In the event of any misbehavior or misconduct by a client while availing our services, we reserve the right to terminate the services immediately, without any prior notice. Misconduct includes, but is not limited to, verbal abuse, harassment, threats, discrimination, or any other action deemed inappropriate or offensive towards any of our team members or other clients.</li>

                 <br/>
                 <li>
                 <h4>Termination of Services </h4> Upon the occurrence of any misconduct, we will exercise our discretion to terminate the services provided to the client involved. This termination may apply to specific services or the entire scope of the agreement, as determined by us.
                 </li>
                 <br/>
                 <li>
                 <h4>No Refund Policy </h4> In cases where the services are terminated due to client misconduct or misbehavior, no refunds or compensation of any kind will be provided. The client will be held liable for the full payment of any outstanding fees or charges related to the terminated services. If any provision of this clause is found to be invalid, illegal, or unenforceable, the remaining provisions shall remain in full force and effect.
                 </li>
                 <br/>
                 <li>
                 <h4>Modification of Terms</h4> We reserve the right to modify these terms and conditions at any time, without prior notice. Clients will be bound by the most recent version of the terms and conditions posted on our official website or communicated directly.
                 </li>
                 <br/>
                 <li>
                 <span>10.</span> You commit to take responsibility for your own actions and understand that the dietitians can only guide and cannot control what you consume vs what was prescribed.
                 </li>
                 <br/>
                 <br/>
                    </ul>
                   <h5>Subject to ‘JAIPUR’ Jurisdiction only.</h5>
        
                </div>
                {/* <!-- Blog Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

    </div>
  );
}

export default TermsConditions;
