
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
function Packages() {
  

  return (
    <div className="App">
 


             

 {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section about-back-img">
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">Our Packages</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Packages</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}

     
       {/* <!-- Our Mission & Vission Section Start --> */}
       <div class="section section-padding" style={{paddingTop : 80, paddingBottom : 40}}>
            <div class="container">
        {/* <!-- Section Title Start --> */}
        <div class="section-title">
                    <h6 class="sub-title">Consultation Options</h6>
                    <h2 class="main-title">Flexible options for your ease</h2>
                </div>
                {/* <!-- Section Title End --> */}
                </div>
                </div>

<div className="row" style={{justifyContent : 'center', marginBottom : 100}}>
        {/* <!-- Our Mission & Vission Section Start --> */}
        <div class="col-lg-5">
            <div class="container">

                {/* <!-- Our Mission Wrapper Start --> */}
                <div class="single-student-widget widget-color-02">
                                    <h4 class="widget-title" style={{paddingTop : 20}}>BASIC 
                                        {/* <p style={{fontSize : '14px'}}>One Month</p> */}
                                            </h4>
                                        

                                        <div class="widget-items">

                                            {/* <!-- Single Item Start --> */}
                                            <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Meal Plan Customised by Dietitian team </div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Weight/Health/Disease Management</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                              {/* <!-- Single Item Start --> */}
                                              <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> With team Dietitian ( Weekly audio consultation)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Standard Support 10:00 am to 07:00pm IST ( Monday - Saturday)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Lifestyle Guidance, Portion control, Eating Right & Maintenance</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    {/* <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div> */}
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                            <h4 class="widget-title" style={{marginTop : 30, background: '#0a4d3c', color: '#fff'}}>Contact Now</h4>

                                        </div>

                                       
                                    </div>
                {/* <!-- Our Mission Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Our Mission & Vission Section End --> */}

        {/* <!-- Our Mission & Vission Section Start --> */}
        <div class="col-lg-5">
            <div class="container">

                {/* <!-- Our Mission Wrapper Start --> */}
                <div class="single-student-widget widget-color-03">
                                        <h4 class="widget-title" style={{paddingTop : 20}}>EXCLUSIVE 
                                        {/* <p style={{fontSize : '14px'}}>One Month</p> */}
                                            </h4>
                                        

                                        <div class="widget-items">

                                            {/* <!-- Single Item Start --> */}
                                            <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Meal Plan Customised by dt. Neha Yaduvanshi </div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Weight/ Health/ Disease Management </div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                              {/* <!-- Single Item Start --> */}
                                              <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular video consultation with dt. Neha Yaduvanshi ( Once in a week)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Standard Support 10:00am to 07:00pm IST ( Monday - Saturday)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Lifestyle Guidance, guides for Portion control, eating out and Maintenance</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                            <h4 class="widget-title" style={{marginTop : 30, background: '#0a4d3c', color: '#fff'}}>Contact Now</h4>

                                        </div>

                                       
                                    </div>
                {/* <!-- Our Mission Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Our Mission & Vission Section End --> */}
        </div>



    

        {/* <!-- Download App Start --> */}
        <div class="section section-padding download-section" style={{paddingTop : 80, paddingBottom : 80}}>

            <img class="shape-01" src="assets/images/shape/shape-7.png" alt="Shape" />
            <img class="shape-02" src="assets/images/shape/shape-8.png" alt="Shape" />

            <div class="container">

                {/* <!-- Download App Wrapper Start --> */}
                <div class="download-app-wrapper" >

                    {/* <!-- Section Title Start --> */}
                    <div class="section-title shape-none">
                        <h6 class="sub-title">Our Payment Process</h6>
                        <h4 class="main-title" style={{fontSize: '20px'}}>You can scan the QR code here, and send us a screenshot once you make the payment via DM on Instagram.<br/><br/>

                        If you require Bank details, please DM us on WhatsApp and we will send it across.

Kindly share the payment confirmation once you do the payment.</h4>

<br/>
<h4  class="main-title" style={{fontSize : 20}}>Google pay,
Phone pay,
Paytm, <br/>
No. - 8447009334</h4>
                    </div>
                    {/* <!-- Section Title End --> */}

                    {/* <!-- Download App Button End --> */}
                    <div class="download-app-btn" >
                        <ul class="app-btn" >
                            <li ><a style={{width : 200, height : 200}}>
                                <img src="assets/images/payment-qr.jpeg"/>
                                </a></li>
                        </ul>
                    </div>
                    {/* <!-- Download App Button End --> */}

                </div>
                {/* <!-- Download App Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Download App End --> */}


        

    </div>
  );
}

export default Packages;
