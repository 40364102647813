
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination,Autoplay } from "swiper";
import HomeAbout from "./HomeAbout";
import HomeProcedures from "./HomeProcedures";
import HomeServices from "./HomeServices";
import HomeContact from "./HomeContact";
import HomePricing from "./HomePricing";
import HomeReviews from "./HomeReviews";
import HomeStories from "./HomeStories";


function Home() {
    const [swiperIndex, setSwiperIndex] = useState(1);


    const socialConnect = () =>{
        return(
            <ul class="social">
            <li><a href="https://www.facebook.com/Dietitiannehayaduvanshi/" target={'_blank'}><i class="icofont-facebook"></i></a></li>
            <li><a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}><i class="icofont-instagram"></i></a></li>
            <li><a href="https://twitter.com/healthfitsquare" target={'_blank'}><i class="icofont-twitter"></i></a></li>
            <li><a href="https://wa.me/+918447009334" target={'_blank'}><i class="icofont-whatsapp"></i></a></li>
        </ul>
        )
    }

  return (
    <div className="App">


{/* <!-- Slider Section Start --> */}
        <div class="slider-section slider-active section">
            <div >
                <div >


                <Swiper
          onSlideChange={(event) => 
            {
                setSwiperIndex(event.activeIndex)}}
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
       
        modules={[EffectFade, Autoplay]}
        className="mySwiper swiper-container swiper-wrapper"
      >

<SwiperSlide>
         {/* <!-- Single Slider Start --> */}
         <div className={swiperIndex == 1 || swiperIndex == 4  ? "swiper-slide animation-style-01 single-slider d-flex align-items-center back-img1 swiper-slide-active" : "swiper-slide animation-style-01 single-slider d-flex align-items-center back-img1"}>
                        <div class="container">

                            {/* <!-- Slider Content Start --> */}
                            <div class="slider-content">
                                <h6 class="sub-title">Welcome to HealthFit Square</h6>
                                <h1 class="main-title">Dedicated to your health.</h1>
                                <p> In 6 years, Across 20 countries dt. NehaYaduvanshi has helped 5000+ people lose fat and manage health conditions.</p>
                                <a href="/contact-us" class="btn btn-primary btn-hover-secondary">Contact Now</a>
                            </div>
                            {/* <!-- Slider Content End --> */}

                        </div>

                        {/* <!-- Slider Social Start --> */}
                        <div class="slider-social">
                            <div class="container">
                                <div class="social-wrapper">
                                    <p>Connect with us:</p>
                                   {socialConnect()}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Slider Social End --> */}
                    </div>
                    {/* <!-- Single Slider End --> */}
        </SwiperSlide>


        <SwiperSlide>
  {/* <!-- Single Slider Start --> */}
  <div className={swiperIndex == 2 ? "swiper-slide animation-style-01 single-slider d-flex align-items-center back-img2 swiper-slide-active" : 'swiper-slide animation-style-01 single-slider d-flex align-items-center back-img2'} >
                        <div class="container">

                            {/* <!-- Slider Content Start --> */}
                            <div class="slider-content">
                                <h6 class="sub-title">Refresh, Renew, Rejuvenate...</h6>
                                <h1 class="main-title">A race <br/> against<br/> trendy diets.</h1>
                                
                                <p>Our mantra (Don't Eat Less Eat Right)</p>
                                <a href="/contact-us" class="btn btn-primary btn-hover-secondary">Contact Now</a>
                            </div>
                            {/* <!-- Slider Content End --> */}

                        </div>

                        {/* <!-- Slider Social Start --> */}
                        <div class="slider-social">
                            <div class="container">
                                <div class="social-wrapper">
                                    <p>Connect with us:</p>
                                    {socialConnect()}
                                   
                                </div>
                            </div>
                        </div>
                        {/* <!-- Slider Social End --> */}
                    </div>
                    {/* <!-- Single Slider End --> */}
        </SwiperSlide>
     
                   

                  
        <SwiperSlide>
                    {/* <!-- Single Slider Start --> */}
                    <div className={swiperIndex == 3 ? "swiper-slide animation-style-01 single-slider d-flex align-items-center back-img3 swiper-slide-active" : "swiper-slide animation-style-01 single-slider d-flex align-items-center back-img3"}>
                        <div class="container">

                            {/* <!-- Slider Content Start --> */}
                            <div class="slider-content">
                                <h6 class="sub-title">Eat Healthy, Stay Healthy</h6>
                                <h1 class="main-title">This lifestyle for your Fitness, not only diet.</h1>
                                <p>Flexible meal plan specialized for you</p>
                                <a href="/contact-us" class="btn btn-primary btn-hover-secondary">Contact Now</a>
                            </div>
                            {/* <!-- Slider Content End --> */}

                        </div>

                        {/* <!-- Slider Social Start --> */}
                        <div class="slider-social">
                            <div class="container">
                                <div class="social-wrapper">
                                    <p>Connect with us:</p>
                                    {socialConnect()}
                                   
                                </div>
                            </div>
                        </div>
                        {/* <!-- Slider Social End --> */}
                    </div>
                    {/* <!-- Single Slider End --> */}
                    </SwiperSlide>
                    </Swiper>

                </div>
            </div>
        </div>
        {/* <!-- Slider Section End --> */}

<HomeAbout />
<HomeProcedures />
<HomeServices />
<HomeReviews />
<HomeStories />
<HomePricing />
<HomeContact />

    </div>
  );
}

export default Home;
