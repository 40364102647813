
import React, { useRef, useState } from "react";
import ServiceData from './json/service.json'
// Import Swiper React components
function HomeServices() {
  

  return (
    <div className="App">
 
 {/* <!-- Blog Section End --> */}
        <div class="section section-padding-02" style={{paddingBottom : 120}}>
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Our Services</h6>
                    <h2 class="main-title">Ease Your Journey of Health</h2>
                </div>
                {/* <!-- Section Title End --> */}

             {/* <!-- Blog Wrapper Start --> */}
             <div class="blog-wrapper">
                    <div class="row gx-xxl-5">

    {ServiceData.map((data, index)=>(
 <div class="col-lg-4" style={{marginTop : '5rem',display : index < 6 ? '' : 'none'}}>

 {/* <!-- Single Blog Start --> */}
 <div class="single-blog" style={{padding: 40}}>
<div>
 <img src={data.image} alt="services" style={{marginTop : '-6rem', height : '12rem', width : '100%'}}/>
     <div class="blog" style={{marginTop : '1rem'}}>
         
         <div class="blog-date-meta">
             <div class="meta" style={{padding : 0}}>
                 <p class="author" >{data.id}: <a href="#">{data.name}</a></p>
             </div>
         </div>
         <p className="limit_text">{data.description}</p>
     </div>
     <div class="rm-btn">
                                    <a href={`/services/${data.id}/${data.name.toLowerCase().replace(/ /g,'_').replace(/\//g, "$")}`} class="btn btn-primary btn-readmore">Read more</a>
                                </div>
     </div>
 </div>
 {/* <!-- Single Blog End --> */}
</div>

    ))}

<div class="col-md-12" style={{marginTop : 60, textAlign : 'center'}}>
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <button type="submit" class="btn btn-secondary btn-hover-primary"
                                                    onClick={()=>{
                                                        window.location.href = '/services'
                                                    }}
                                                    >View More</button>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
               

                    

                            {/* <div class="col-lg-4">
                        
                            <div class="single-blog">
                                <div class="blog">
                                    <div class="blog-date-meta">
                                        <span>
                                        <strong>24</strong>
                                        Feb
                                    </span>
                                        <div class="meta">
                                            <p class="author">17: <a href="#">Lactose Intolerance Management</a></p>
                                            
                                        </div>
                                    </div>

                                    <p>Lactose intolerance is a digestive disorder caused by the inability to digest lactose, the main carbohydrate in dairy products. It can cause various symptoms, including bloating, diarrhea and abdominal cramps.</p>
                                </div>
                            </div>
                      
                            </div> 
                            */}

                  
                    </div>
                </div>
                {/* <!-- Blog Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

         {/* <!-- Download Section Start --> */}
         <div class="section">
            <div class="">

                {/* <!-- Download Wrapper Start --> */}
                <div class="download-wrapper">

                    <img class="shape-01" src="/assets/images/shape/shape-7.png" alt="Shape" />
                    <img class="shape-02" src="/assets/images/shape/shape-8.png" alt="Shape" />

                    {/* <!-- Section Title Start --> */}
                    <div class="section-title shape-none">
                        <h6 class="sub-title">Transformation</h6>
                        <h2 class="main-title">If they can, so can you.  </h2><br/>
                        <h2 style={{color : '#fff'}}>Creating diet plan that lead to lifestyle changing experiences.</h2>
                    </div>
                    {/* <!-- Section Title End --> */}

                   

                </div>
                {/* <!-- Download Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Download Section End --> */}

    </div>
  );
}

export default HomeServices;
