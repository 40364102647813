
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
function Payment() {
  

  return (
    <div className="App">
 


             

 {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section about-back-img">
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">Our Payment Process</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Payment Process</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}

     

        {/* <!-- Download App Start --> */}
        <div class="section section-padding download-section" style={{paddingTop : 150, paddingBottom : 150}}>

            <img class="shape-01" src="assets/images/shape/shape-7.png" alt="Shape" />
            <img class="shape-02" src="assets/images/shape/shape-8.png" alt="Shape" />

            <div class="container">

                {/* <!-- Download App Wrapper Start --> */}
                <div class="download-app-wrapper">

                    {/* <!-- Section Title Start --> */}
                    <div class="section-title shape-none">
                        {/* <h6 class="sub-title">Our Payment Process</h6> */}
                        <h4 class="main-title" style={{fontSize: '20px'}}>You can scan the QR code here, and send us a screenshot once you make the payment via DM on Instagram.<br/><br/>

                        If you require Bank details, please DM us on WhatsApp and we will send it across.

Kindly share the payment confirmation once you do the payment.</h4>
<br/>
<h4  class="main-title" style={{fontSize : 20}}>Google pay,
Phone pay,
Paytm, <br/>
No. - 8447009334</h4>
                    </div>
                    {/* <!-- Section Title End --> */}

                    {/* <!-- Download App Button End --> */}
                    <div class="download-app-btn">
                        <ul class="app-btn">
                            <li><a>
                            <img src="assets/images/payment-qr.jpeg"/>
                                </a></li>
                        </ul>
                    </div>
                    {/* <!-- Download App Button End --> */}

                </div>
                {/* <!-- Download App Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Download App End --> */}


        

    </div>
  );
}

export default Payment;
