
import React, { useRef, useState } from "react";
// Import Swiper React components
function HomePricing() {
  

  return (
    <div className="App">
 

{/* <!-- Blog Section End --> */}
<div class="section section-padding-02" >
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Our Pricing Plans</h6>
                    <h2 class="main-title">Simple & transparent plans, choose what suits you best.</h2>
                </div>
                {/* <!-- Section Title End --> */}

 {/* <!-- Student's Widget Start --> */}
                        <div class="students-widget">
                            <div class="row">
                                <div class="col-xxl-6">
                                    {/* <!-- Student's Widget Start --> */}
                                    <div class="single-student-widget widget-color-02">
                                    <h4 class="widget-title" style={{paddingTop : 20}}>BASIC 
                                        {/* <p style={{fontSize : '14px'}}>One Month</p> */}
                                            </h4>
                                        

                                        <div class="widget-items">

                                            {/* <!-- Single Item Start --> */}
                                            <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Meal Plan Customised by Dietitian team </div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Weight/Health/Disease Management</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                              {/* <!-- Single Item Start --> */}
                                              <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> With team Dietitian ( Weekly audio consultation)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Standard Support 10:00 am to 07:00pm IST ( Monday - Saturday)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Lifestyle Guidance, Portion control, Eating Right & Maintenance</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    {/* <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div> */}
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                            <h4 class="widget-title" style={{marginTop : 30, background: '#0a4d3c', color: '#fff'}}>Contact Now</h4>

                                        </div>

                                       
                                    </div>
                                    
                                    {/* <!-- Student's Widget End --> */}
                                </div>


                                <div class="col-xxl-6">
                                    {/* <!-- Student's Widget Start --> */}
                                    <div class="single-student-widget widget-color-03">
                                        <h4 class="widget-title" style={{paddingTop : 20}}>EXCLUSIVE 
                                        {/* <p style={{fontSize : '14px'}}>One Month</p> */}
                                            </h4>
                                        

                                        <div class="widget-items">

                                            {/* <!-- Single Item Start --> */}
                                            <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Meal Plan Customised by dt. Neha Yaduvanshi </div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Weight/ Health/ Disease Management </div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                              {/* <!-- Single Item Start --> */}
                                              <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular video consultation with dt. Neha Yaduvanshi ( Once in a week)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Standard Support 10:00am to 07:00pm IST ( Monday - Saturday)</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Lifestyle Guidance, guides for Portion control, eating out and Maintenance</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}


                                             {/* <!-- Single Item Start --> */}
                                             <div class="single-item" style={{paddingRight : 10}}>
                                                <div class="item-flag">
                                                   
                                                    <div class="title" style={{whiteSpace : 'normal'}}><span class="dot"></span> Regular follow up with flexible Diet Plan</div>
                                                </div>
                                               
                                            </div>
                                            {/* <!-- Single Item End --> */}

                                            <h4 class="widget-title" style={{marginTop : 30, background: '#0a4d3c', color: '#fff'}}>Contact Now</h4>

                                        </div>

                                       
                                    </div>
                                    
                                    {/* <!-- Student's Widget End --> */}
                                </div>

                                
                            </div>
                        </div>
                        {/* <!-- Student's Widget End --> */}


                        </div>
                        </div>
                
                    

    </div>
  );
}

export default HomePricing;
