import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "./components/Header";

class Routing extends Component {

   render(){
       return(
        <BrowserRouter>
        <div>
        <div class="whats-float">
    <a href="https://wa.me/+918447009334" target={'_blank'}>
        <i  class="fa fa-whatsapp"></i>
        {/* <span>WhatsApp<br /><small>+90 12 123456</small></span> */}
    </a>
</div>
<div class="insta-float">
    <a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}>
        <i class="fa fa-instagram"></i>
        {/* <span>WhatsApp<br /><small>+90 12 123456</small></span> */}
    </a>
</div>

<div class="call-float">
    <a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}>
        <i class="fa fa-phone"></i>
        {/* <span>WhatsApp<br /><small>+90 12 123456</small></span> */}
    </a>
</div>
          </div>
        <div>
      
           <Header />
    </div>
 
    
    </BrowserRouter>
    
       )
   }
}

export default Routing;