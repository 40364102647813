
import React, { useRef, useState } from "react";
import ServiceData from './json/service.json'
// Import Swiper React components
function Services() {
  

  return (
    <div className="App">

        {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section contact-back-img" >
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">Services</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Services</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}
 
 {/* <!-- Blog Section End --> */}
        <div class="section section-padding-02 section-padding">
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Our Services</h6>
                    <h2 class="main-title">Transform your health with personalized nutrition plans</h2>
                </div>
                {/* <!-- Section Title End --> */}

                {/* <!-- Blog Wrapper Start --> */}
                <div class="blog-wrapper">
                    <div class="row gx-xxl-5">

    {ServiceData.map((data, index)=>(
 <div class="col-lg-4" style={{marginTop : '5rem'}}>

 {/* <!-- Single Blog Start --> */}
 <div class="single-blog" style={{padding: 40}}>
<div>
 <img src={data.image} alt="services" style={{marginTop : '-6rem', height : '12rem', width : '100%'}}/>
     <div class="blog" style={{marginTop : '1rem'}}>
         
         <div class="blog-date-meta">
             <div class="meta" style={{padding : 0}}>
                 <p class="author" >{data.id}: <a href="#">{data.name}</a></p>
             </div>
         </div>
         <p className="limit_text">{data.description}</p>
     </div>
     <div class="rm-btn">
                                    <a href={`/services/${data.id}/${data.name.toLowerCase().replace(/ /g,'_').replace(/\//g, "$")}`} class="btn btn-primary btn-readmore">Read more</a>
                                </div>
     </div>
 </div>
 {/* <!-- Single Blog End --> */}
</div>

    ))}
               

                    

                            {/* <div class="col-lg-4">
                        
                            <div class="single-blog">
                                <div class="blog">
                                    <div class="blog-date-meta">
                                        <span>
                                        <strong>24</strong>
                                        Feb
                                    </span>
                                        <div class="meta">
                                            <p class="author">17: <a href="#">Lactose Intolerance Management</a></p>
                                            
                                        </div>
                                    </div>

                                    <p>Lactose intolerance is a digestive disorder caused by the inability to digest lactose, the main carbohydrate in dairy products. It can cause various symptoms, including bloating, diarrhea and abdominal cramps.</p>
                                </div>
                            </div>
                      
                            </div> 
                            */}

                  
                    </div>
                </div>
                {/* <!-- Blog Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

    </div>
  );
}

export default Services;
