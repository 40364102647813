
import React, { useRef, useState } from "react";
// Import Swiper React components
function HomeAbout() {
  

  return (
    <div className="App">
       

       {/* <!-- Download Section Start --> */}
        <div class="section">
            <div class="">

                {/* <!-- Download Wrapper Start --> */}
                <div class="download-wrapper">

                    <img class="shape-01" src="/assets/images/shape/shape-7.png" alt="Shape" />
                    <img class="shape-02" src="/assets/images/shape/shape-8.png" alt="Shape" />

                    {/* <!-- Section Title Start --> */}
                    <div class="section-title shape-none">
                        <h6 class="sub-title">Always For your Health, At Our Best</h6>
                        <h2 class="main-title">Since 2014, <br/> We provide best
service to our clients.</h2>
                    </div>
                    {/* <!-- Section Title End --> */}

                   

                </div>
                {/* <!-- Download Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Download Section End --> */}



 {/* <!-- About Section Start --> */}
        <div class="section section-padding bg-grey overflow-hidden">
            <div class="container">

                {/* <!-- About Wrapper Start --> */}
                <div class="about-wrapper">
                    <div class="row align-items-center gx-xxl-5">
                        <div class="col-lg-5">
                            {/* <!-- About Images Start --> */}
                            <div class="about-images">
                                {/* <img src="assets/images/shape/shape-2.png" alt="shape" class="shape" /> */}
                                <div class="images">
                                    <img src="assets/images/neha.jpg" alt="About" />
                                </div>
                                <div class="about-btn">
                                    <a href="/contact-us" class="btn btn-primary btn-hover-secondary">Meet the Dietician</a>
                                </div>
                            </div>
                            {/* <!-- About Images End --> */}
                        </div>
                        <div class="col-lg-7">
                            {/* <!-- About Content Start --> */}
                            <div class="about-content bg-white">
                                <img class="shape" src="/assets/images/shape/shape-1.png" alt="shape" />
                                <div class="content-wrapper">
                                    <h6 class="sub-title">6+ years of experience</h6>
                                    <h2 class="main-title">Welcome to HealthFit Square <br/></h2>
<h3>Dt Neha Yaduvanshi</h3>
                                    <p>Dietitian Neha Yaduvanshi founded Healthfitsquare in 2019, a diet clinic situated in Jaipur, with a mission to make everyone healthy & happy by healthy eating and lifestyle habits. </p>
                                    <span class="count">Best Health Advices</span>
                                    <p>If you are looking for a complete health plan for you and your family, then you are right place. Here at Health fit Square we consider our personalized nutrition plan a game changer for you. <br/>We create personalized diet plans keeping the calories you should be consuming each day; no more, no less. </p>
                                </div>
                            </div>
                            {/* <!-- About Content End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- About Wrapper End --> */}

            </div>
        </div>
        {/* <!-- About Section End --> */}
    </div>
  );
}

export default HomeAbout;
