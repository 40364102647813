
import React, { useRef, useState } from "react";
// Import Swiper React components
function Yoga() {
  

  return (
    <div className="App">

        {/* <!-- Page banner Section Start --> */}
        <div class="section page-banner-section contact-back-img" >
            <div class="container">

                {/* <!-- Page banner Section Start --> */}
                <div class="page-banner-content">
                    <h2 class="page-title">Yoga & Workout</h2>

                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active">Yoga & Workout</li>
                    </ul>
                </div>
                {/* <!-- Page banner Section End --> */}

            </div>
        </div>
        {/* <!-- Page banner Section End --> */}
 
 {/* <!-- Blog Section End --> */}
        <div class="section section-padding-02 section-padding">
            <div class="container">

                {/* <!-- Section Title Start --> */}
                <div class="section-title">
                    <h6 class="sub-title">Yoga Aasans</h6>
                    <h2 class="main-title">Latest Article of Nutrition</h2>
                </div>
                {/* <!-- Section Title End --> */}

                {/* <!-- Blog Wrapper Start --> */}
                <div class="blog-wrapper">
                    <div class="row gx-xxl-5">

                        <div class="col-lg-4">

                            {/* <!-- Single Blog Start --> */}
                            <div class="single-blog" style={{paddingTop : 10}}>
                                <div class="blog">
                                    <div class="blog-date-meta">
                                        <span>
                                        <strong>24</strong>
                                        Feb
                                    </span>
                                        <div class="meta">
                                            <p class="author" >01: <a href="#">Surya Namaskar</a></p>
                                            
                                        </div>
                                    </div>

                                    <p style={{marginTop : 30}}>Surya Namaskar or Sun Salutation is a sequence of 12 powerful yoga poses. Besides being a great cardiovascular workout, Surya Namaskar is also known to have an immensely positive impact on the body & mind.</p>
                                </div>
                            </div>
                            {/* <!-- Single Blog End --> */}

                        </div>




                  
                    </div>
                </div>
                {/* <!-- Blog Wrapper End --> */}

            </div>
        </div>
        {/* <!-- Blog Section End -->. */}

    </div>
  );
}

export default Yoga;
